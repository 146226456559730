.rmdp-container {
    width: 100%!important;
}

//instrument style in segmented notifications
.instrumentRow{
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.instrumentCheckbox{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

//DateChanger overlay for closure in segmented notifications
.overlayer{
	position: absolute;
	top: -35%;
	left: -140%!important;
	width: 100vw;
	height: 100vh;
	z-index: 99;
}

[dir] .mbsc-ios.mbsc-textfield-stacked {
	padding-top: 0!important;
}

[dir] .mbsc-ios.mbsc-textfield-wrapper-outline {
	margin: 0!important;
}

.mbsc-ios.mbsc-textfield-stacked {
	height: 3em!important;
}

@media (max-width: 700px) {
	//DateChanger overlay for closure in segmented notifications
	.overlayer{
		top: -25%;
		left: -10%!important;
	}
}
/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
